@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-display: swap;
  font-weight: 500;
  src: url("ibm-plex-mono-latin-500-italic.65bdd41d.woff2") format("woff2"), url("ibm-plex-mono-latin-500-italic.03433235.woff") format("woff");
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src: url("roboto-latin-100-normal.f5111a14.woff2") format("woff2"), url("roboto-latin-100-normal.483a8292.woff") format("woff");
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("roboto-latin-300-normal.5906ce62.woff2") format("woff2"), url("roboto-latin-300-normal.db0bea59.woff") format("woff");
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-latin-400-normal.c853c8d7.woff2") format("woff2"), url("roboto-latin-400-normal.1628c855.woff") format("woff");
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("roboto-latin-500-normal.2b6f89ba.woff2") format("woff2"), url("roboto-latin-500-normal.7ffda42b.woff") format("woff");
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("roboto-latin-700-normal.df80619b.woff2") format("woff2"), url("roboto-latin-700-normal.b0587056.woff") format("woff");
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: url("roboto-latin-900-normal.0bebb267.woff2") format("woff2"), url("roboto-latin-900-normal.5d872755.woff") format("woff");
}
/*# sourceMappingURL=index.2600ff7a.css.map */
